<mat-card>
    <h2>Meetings</h2>
    <h3>Your meetings in current date ({{ startOfMonth | date: 'YYYY-MM-dd' }} - {{ now | date: 'YYYY-MM-dd' }})</h3>

    <button (click)="loginWithGoogle()" mat-raised-button class="google-login-btn" *ngIf="googleLoginRequired">
        Login with Google to see meetings
    </button>
    <ng-container *ngIf="!googleLoginRequired">
        <table mat-table *ngIf="meetings$ | async as meetings; else loading" [dataSource]="meetings" class="meetings">
            <ng-container matColumnDef="meeting">
                <th mat-header-cell *matHeaderCellDef class="p-16 regular-text">Meeting</th>
                <td mat-cell *matCellDef="let meeting">
                    <div class="meeting-details">
                        <strong>{{ meeting.name }}</strong>
                        <ul>
                            <li *ngFor="let date of meeting.events">{{ date | date: 'YYYY-MM-dd HH:MM' }}</li>
                        </ul>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef class="p-16 regular-text duration-header">Duration</th>
                <td mat-cell *matCellDef="let meeting">
                    <span class="duration">{{ meeting.durationMin | worklog }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="component">
                <th mat-header-cell *matHeaderCellDef class="p-16 regular-text">Component</th>
                <td mat-cell *matCellDef="let meeting">
                    {{ meeting.component }}
                    <meetings-autocomplete [meeting]="meeting" [options]="options$"></meetings-autocomplete>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['meeting', 'duration', 'component']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['meeting', 'duration', 'component']"></tr>
        </table>
    </ng-container>
</mat-card>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
