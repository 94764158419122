<div>
    <mat-card class="filters-container">
        <div class="filters-item">
            <h3>Book date</h3>
            <div></div>
            <div fxLayoutAlign="start none">
                <mat-form-field appearance="fill">
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Start date" />
                        <input
                            matEndDate
                            formControlName="end"
                            placeholder="End date"
                            (dateChange)="onChangeEventEnd($event.value)"
                        />
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>
        </div>
        <div class="filters-item">
            <h3 class="project-h3">Project</h3>

            <div class="project-selector">
                <mat-form-field appearance="fill">
                    <mat-label> Select Jira project </mat-label>
                    <mat-select [formControl]="jiraProject" (ngModelChange)="OnChangesJiraProject($event)">
                        <mat-option *ngFor="let project of jiraProjects" [value]="project.key">{{
                            project.name
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="favorite-marks">
                    <button
                        class="mark-favorite"
                        *ngIf="jiraProject.value !== favoriteProject"
                        (click)="OnClickMarkFavorite()"
                        mat-raised-button
                    >
                        Mark as favorite project
                    </button>
                    <button
                        class="mark-favorite"
                        *ngIf="jiraProject.value === favoriteProject && favoriteProject !== ''"
                        (click)="OnClickUnMarkFavorite()"
                        mat-raised-button
                    >
                        Unset favorite project
                    </button>
                </div>
            </div>
        </div>
    </mat-card>
</div>
