export const environment = {
    production: false,
    auth: {
        appId: 'time-booking',
        baseUrl: 'https://api-staging.viessmann.com',
        registerBaseUrl: 'https://account-staging.viessmann.com',
    },
    google: {
        clientId: '180893468686-osrooisr1aovk6kesv5hna9itm39e4d0.apps.googleusercontent.com',
    },
    apiUrl: 'https://api-staging.viessmann.com',
};
