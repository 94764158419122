<mat-toolbar color="primary">
    <img src="../../assets/viessmann-white.svg" class="logo-image" />
    <div class="rectangle"></div>
    <span class="name">Time booking</span>

    <div fxFlex="1 0 auto"></div>

    <vih-app-switcher></vih-app-switcher>
    <vih-login [appManagesAuthentication]="true" [authenticated]="authenticated$"></vih-login>
</mat-toolbar>
