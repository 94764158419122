/* eslint-disable sonarjs/no-identical-functions */
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { startOfMonth } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FiltersService } from 'src/app/services/filters.service';
import { GoogleCalenderService } from 'src/app/services/google-calender.service';
import { JiraService } from 'src/app/services/jira.service';
import { MeetingsService } from 'src/app/services/meetings.service';

@Component({
    selector: 'meetings',
    templateUrl: './meetings.component.html',
    styleUrls: ['./meetings.component.scss'],
})
export class MeetingsComponent implements OnInit {
    constructor(
        public meetingsService: MeetingsService,
        public socialAuthService: SocialAuthService,
        public googleCalenderService: GoogleCalenderService,
        public snackBar: MatSnackBar,
        public jiraService: JiraService,
        public filtersService: FiltersService
    ) {}

    now = this.filtersService.getEndDate();
    startOfMonth = startOfMonth(this.now);
    meetings$ = this.googleCalenderService.calenderEvents$.pipe(
        catchError((e) => {
            this.snackBar.open('Error while connecting to google');
            // eslint-disable-next-line no-console
            console.error(e);
            return [];
        })
    );

    googleLoginRequired = true;
    options$ = new BehaviorSubject<string[]>([]);

    async ngOnInit() {
        this.filtersService.favoriteProject$.subscribe(async (project) => {
            this.options$.next(await this.jiraService.getComponentsPromise(project));
        });

        this.socialAuthService.authState.subscribe((state) => {
            if (state.authToken) {
                this.googleLoginRequired = false;
            }
        });

        this.filtersService.range$.subscribe((range) => {
            this.now = range.end;
            this.startOfMonth = range.start;

            this.meetings$ = this.googleCalenderService.calenderEvents$.pipe(
                catchError((e) => {
                    this.snackBar.open('Error while connecting to google');
                    // eslint-disable-next-line no-console
                    console.error(e);
                    return [];
                })
            );
        });
    }

    loginWithGoogle(): void {
        this.socialAuthService
            .signIn(GoogleLoginProvider.PROVIDER_ID)
            // eslint-disable-next-line no-console
            .then((v) => console.log(v))
            // eslint-disable-next-line no-console
            .catch((e) => console.error(e));
    }
}
