/* eslint-disable sonarjs/no-identical-functions */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { startOfMonth } from 'date-fns';
import { catchError, finalize } from 'rxjs/operators';
import { FiltersService } from 'src/app/services/filters.service';
import { JiraService, JiraTicket, JiraTicketWithParent } from 'src/app/services/jira.service';

@Component({
    selector: 'tickets',
    templateUrl: './tickets.component.html',
    styleUrls: ['./tickets.component.scss'],
})
export class TicketsComponent implements OnInit, AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;

    loading = true;
    dataSource = new MatTableDataSource<JiraTicketWithParent>([]);
    constructor(
        public jiraService: JiraService,
        private snackBar: MatSnackBar,
        private filtersService: FiltersService
    ) {}

    ngOnInit() {
        this.filtersService.range$.subscribe((range) => {
            this.now = range.end;
            this.startOfMonth = range.start;

            this.jiraService
                .ticketsWithTime()
                .pipe(
                    finalize(() => (this.loading = false)),
                    catchError((e) => {
                        this.snackBar.open(
                            'Error while fetching jira ticket. Ensure you are logged in into jira, as for not this tool use your active browser session.'
                        );
                        // eslint-disable-next-line no-console
                        console.error(e);
                        return [];
                    })
                )
                .subscribe((data) => {
                    this.dataSource.data = <JiraTicket[]>data;
                });
        });

        this.now = this.filtersService.getEndDate();
        this.startOfMonth = startOfMonth(this.now);
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    now: number | Date;
    startOfMonth: Date;
}
