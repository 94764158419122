import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from './+content/content.component';
import { ForbiddenComponent } from './+forbidden/forbidden.component';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
    {
        path: 'forbidden',
        pathMatch: 'full',
        component: ForbiddenComponent,
    },
    {
        path: '',
        pathMatch: 'full',
        canActivate: [AuthGuardService],
        component: ContentComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
