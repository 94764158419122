import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SocialAuthService } from 'angularx-social-login';
import { differenceInMinutes } from 'date-fns';
// import { differenceInMinutes, endOfMonth, startOfMonth } from 'date-fns';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { FiltersService } from './filters.service';

export interface AggregatedEvent {
    name: string;
    count: number;
    durationMin: number;
    events: Date[];
}

@Injectable({
    providedIn: 'root',
})
export class GoogleCalenderService {
    constructor(
        private http: HttpClient,
        private socialAuthService: SocialAuthService,
        private filtersService: FiltersService
    ) {}

    calenderEvents$: Observable<AggregatedEvent[]> = this.socialAuthService.authState.pipe(
        filter((authState) => authState.authToken !== undefined),
        switchMap((authState) => this.getAllEvents(authState.authToken))
    );

    getAllEvents(authToken: string) {
        return this.http
            .get<CalenderListResponse>('https://www.googleapis.com/calendar/v3/users/me/calendarList', {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            })
            .pipe(
                switchMap((calenderList) => {
                    const calender = calenderList.items.find(
                        (calender) => calender.accessRole === 'owner' && calender.primary === true
                    );

                    return this.http
                        .get<CalenderEventsResponse>(
                            `https://www.googleapis.com/calendar/v3/calendars/${calender?.id}/events`,
                            {
                                headers: {
                                    Authorization: `Bearer ${authToken}`,
                                },
                                params: {
                                    orderBy: 'startTime',
                                    singleEvents: true,
                                    timeMin: this.filtersService.getStartDate().toISOString(),
                                    timeMax: this.filtersService.getEndDate().toISOString(),
                                },
                            }
                        )
                        .pipe(map((data) => this.aggregateCalenderEvents(data.items)));
                })
            );
    }

    aggregateCalenderEvents(events: CalenderEvent[]): AggregatedEvent[] {
        const eventNames = [...new Set(events.map((event) => event.summary))];
        return eventNames
            .map((name) => {
                const matchingEvents = events
                    .filter((event) => event.status === 'confirmed')
                    .filter((event) => event.summary === name)
                    .filter((event) => event.transparency !== 'transparent');
                const durationMin = matchingEvents
                    .filter((event) => event.status === 'confirmed')
                    .map((event) => [new Date(event?.start?.dateTime), new Date(event?.end?.dateTime)])
                    .reduce((sum, [start, end]) => sum + differenceInMinutes(end, start), 0);
                const durationMinRoundedToHalfHour = Math.ceil(durationMin / 30) * 30;
                return {
                    name,
                    count: matchingEvents.length,
                    durationMin: durationMinRoundedToHalfHour,
                    events: matchingEvents.map((event) => new Date(event.start.dateTime)),
                };
            })
            .sort((eventA, eventB) => eventB.durationMin - eventA.durationMin);
    }
}

interface CalenderListResponse {
    etag: string;
    kind: string;
    items: {
        accessRole: 'owner' | 'reader' | 'freeBusyReader';
        backgroundColor: string;
        colorId: string;
        conferenceProperties: { allowedConferenceSolutionTypes: string[] };
        defaultReminders: { method: string; minutes: number }[];
        etag: string;
        foregroundColor: string;
        id: string;
        kind: string;
        primary: boolean;
        selected: boolean;
        summary: string;
        timeZone: string;
    }[];
}

interface CalenderEventsResponse {
    etag: string;
    nextPageToken: string;
    timezone: string;
    items: CalenderEvent[];
}

interface CalenderEvent {
    kind: 'calendar#event';
    etag: string;
    id: string;
    status: string;
    htmlLink: string;
    created: string;
    updated: string;
    summary: string;
    description: string;
    location: string;
    colorId: string;
    creator: {
        id: string;
        email: string;
        displayName: string;
        self: boolean;
    };
    organizer: {
        id: string;
        email: string;
        displayName: string;
        self: boolean;
    };
    start: {
        date: string;
        dateTime: string;
        timeZone: string;
    };
    end: {
        date: string;
        dateTime: string;
        timeZone: string;
    };
    endTimeUnspecified: boolean;
    recurrence: [string];
    recurringEventId: string;
    originalStartTime: {
        date: string;
        dateTime: string;
        timeZone: string;
    };
    transparency: string;
    visibility: string;
    iCalUID: string;
    sequence: number;
    attendees: {
        id: string;
        email: string;
        displayName: string;
        organizer: boolean;
        self: boolean;
        resource: boolean;
        optional: boolean;
        responseStatus: string;
        comment: string;
        additionalGuests: number;
    }[];
    attendeesOmitted: boolean;
    hangoutLink: string;

    anyoneCanAddSelf: boolean;
    guestsCanInviteOthers: boolean;
    guestsCanModify: boolean;
    guestsCanSeeOtherGuests: boolean;
    privateCopy: boolean;
    locked: boolean;

    source: {
        url: string;
        title: string;
    };
    eventType: string;
}
