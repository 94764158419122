import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MeetingsService {
    meetingComponents$ = new BehaviorSubject<[string, { timeSpent: number; component: string }][]>([]);

    setMeetingComponent(meetingId: string, timeSpent: number, component: string) {
        if (this.meetingComponents$.value.find(([key]) => key === meetingId)) {
            this.meetingComponents$.next(
                this.meetingComponents$.value.map((meeting) => {
                    if (meeting[0] === meetingId) {
                        return [meetingId, { timeSpent, component }];
                    }
                    return meeting;
                })
            );
        } else {
            this.meetingComponents$.next([...this.meetingComponents$.value, [meetingId, { timeSpent, component }]]);
        }
    }
}
