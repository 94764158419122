<div fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="row">
        <div>
            <filters></filters>
            <tickets></tickets>
            <meetings></meetings>
        </div>

        <summary></summary>
    </div>
</div>
