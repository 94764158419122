<div class="example-full-width" appearance="fill">
    <mat-label>Component</mat-label>
    <input
        type="text"
        placeholder="Pick one"
        aria-label="Component"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option"> {{ option }} </mat-option>
    </mat-autocomplete>
</div>
