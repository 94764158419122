import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
    constructor(private auth: AuthService, private router: Router) {}

    canActivate(): Observable<boolean> {
        return this.auth.getCsrfToken$().pipe(
            map((token) => {
                if (!token) {
                    return false;
                }
                return jwtDecode<{ identityType: string }>(token).identityType?.toLowerCase().startsWith('vi');
            }),
            tap((allowed) => {
                if (!allowed) {
                    this.router.navigate(['/forbidden']);
                }
            })
        );
    }
}
