import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GlobalHeaderModule } from '@vi/global-header';
import { GoogleLoginProvider, SocialLoginModule } from 'angularx-social-login';
import { environment } from 'src/environments/environment';
import { bookingDialogComponent } from './+content/bookingDialog/bookingDialog.component';
import { ContentComponent } from './+content/content.component';
import { FiltersComponent } from './+content/filters/filters.component';
import { MeetingsAutocompleteComponent } from './+content/meetings/meetings-autocomplete.component';
import { MeetingsComponent } from './+content/meetings/meetings.component';
import { SummaryComponent } from './+content/summary/summary.component';
import { TicketsComponent } from './+content/tickets/tickets.component';
import { ForbiddenComponent } from './+forbidden/forbidden.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BodyComponent } from './core/body/body.component';
import { FooterComponent } from './core/footer/footer.component';
import { HeaderComponent } from './core/header/header.component';
import { WorklogPipe } from './pipe/worklog.pipe';
import { HttpAuthInterceptorService } from './services/http-auth-interceptor.service';
import { MomentUtcDateAdapter } from './services/MatMomentDateAdapter';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        BodyComponent,
        ContentComponent,
        FooterComponent,
        TicketsComponent,
        SummaryComponent,
        MeetingsComponent,
        WorklogPipe,
        ForbiddenComponent,
        bookingDialogComponent,
        MeetingsAutocompleteComponent,
        FiltersComponent,
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        GlobalHeaderModule.forRoot({
            appId: environment.auth.appId,
            baseUrl: environment.auth.baseUrl,
            accountAppUrl: environment.auth.registerBaseUrl,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            partnerPortalBaseUrl: <any>undefined,
        }),
        FlexModule,
        MatIconModule,
        MatToolbarModule,
        MatButtonModule,
        MatCheckboxModule,
        MatSidenavModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatCardModule,
        MatInputModule,
        MatTableModule,
        MatDialogModule,
        MatSnackBarModule,
        MatSelectModule,
        MatProgressBarModule,
        FormsModule,
        ReactiveFormsModule,
        SocialLoginModule,
        MatSortModule,
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpAuthInterceptorService,
            multi: true,
        },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: DateAdapter, useClass: MomentUtcDateAdapter, deps: [MAT_DATE_LOCALE] },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(environment.google.clientId, {
                            scope: 'https://www.googleapis.com/auth/calendar.readonly',
                        }),
                    },
                ],
            },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
