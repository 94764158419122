/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Rx from 'rxjs';
import { environment } from 'src/environments/environment';
import { FiltersService } from './filters.service';

@Injectable({
    providedIn: 'root',
})
export class JiraService {
    constructor(private http: HttpClient, private route: ActivatedRoute, private filtersService: FiltersService) {}

    getProjects() {
        return this.http
            .get<{ key: string; name: string }[]>(`${environment.apiUrl}/account-booking/v1/jira/projects`)
            .toPromise();
    }

    getComponents(component: string) {
        return this.http.get<string[]>(`${environment.apiUrl}/account-booking/v1/jira/components/${component}`);
    }

    async getComponentsPromise(component: string) {
        return await this.http
            .get<string[]>(`${environment.apiUrl}/account-booking/v1/jira/components/${component}`)
            .toPromise();
    }

    getUserTicketsPromise() {
        const params = new HttpParams()
            .set('startDate', this.filtersService.getStartDate().toString())
            .set('endDate', this.filtersService.getEndDate().toString());

        return this.http
            .get<JiraTicketWithParent[]>(`${environment.apiUrl}/account-booking/v1/jira/user-tickets`, {
                params: params,
            })
            .toPromise();
    }

    getLoggedTimePromise(ticket: string, startDate: Date, endDate: Date) {
        const params = new HttpParams()
            .set('ticket', ticket)
            .set('startDate', startDate.toISOString())
            .set('endDate', endDate.toISOString());

        return this.http
            .get<number>(`${environment.apiUrl}/account-booking/v1/jira/logged-time`, {
                params: params,
            })
            .toPromise();
    }

    ticketsWithTime() {
        return Rx.from(
            (async () => {
                const result = [];
                for await (const ticket of await this.getUserTicketsPromise()) {
                    const timeSpent = await this.getLoggedTimePromise(
                        ticket.ticket,
                        this.filtersService.getStartDate(),
                        this.filtersService.getEndDateDay()
                    );
                    const component = this.getTicketComponent(ticket);
                    result.push({ ...ticket, component, timeSpent });
                }

                return result;
            })()
        );
    }

    private getTicketComponent(ticket: JiraTicketWithParent) {
        if (ticket.parent && !ticket.component) {
            return ticket.parent.component;
        }

        return ticket.component;
    }
}

export interface JiraTicketWithParent extends JiraTicket {
    parent?: JiraTicket;
}
export interface JiraTicket {
    ticket: string;
    description: string;
    url: string;
    component: string;
    issueType: string;
    timeSpent?: number;
}

export interface JiraTicketListResponse {
    expand: string;
    startAt: number;
    maxResults: number;
    total: number;
    issues: {
        id: string;
        self: string;
        key: string;
        fields: {
            summary: string;
            issuetype: {
                iconUrl: string;
                name: string;
            };
            components: JiraComponent[];
        };
    }[];
}

interface JiraComponent {
    name: string;
}
