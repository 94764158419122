import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FiltersService } from 'src/app/services/filters.service';
import { AggregatedEvent } from 'src/app/services/google-calender.service';
import { JiraService } from 'src/app/services/jira.service';
import { MeetingsService } from 'src/app/services/meetings.service';

/**
 * @title Filter autocomplete
 */
@Component({
    selector: 'meetings-autocomplete',
    templateUrl: './meetings-autocomplete.component.html',
    styleUrls: ['./meetings.component.scss'],
})
export class MeetingsAutocompleteComponent implements OnInit {
    myControl = new FormControl();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filteredOptions: Observable<string[]>;

    @Input() meeting: AggregatedEvent;
    @Input() options: BehaviorSubject<string[]>;

    constructor(
        public meetingsService: MeetingsService,
        public jiraService: JiraService,
        public filtersService: FiltersService
    ) {}

    async ngOnInit() {
        this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value))
        );

        this.myControl.valueChanges.subscribe((component) => {
            this.meetingsService.setMeetingComponent(this.meeting.name, this.meeting.durationMin, component);
        });

        this.options.subscribe((value) => {
            this.filteredOptions = this.myControl.valueChanges.pipe(
                startWith(''),
                map((value) => this._filter(value))
            );
            this.preselectJiraComponent(value);
        });
    }

    private preselectJiraComponent(components: string[]): void {
        const meetingFormat = this.meeting.name.match(/([^\S\s]*(\d{10})[^\S\s]*)/g);
        if (meetingFormat) {
            const matchedComponent = components.find((components) => components.search(meetingFormat[0]) >= 0);
            if (matchedComponent) {
                this.myControl.setValue(matchedComponent);
            }
        }
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.options.value.filter((option) => option.toLowerCase().includes(filterValue));
    }
}
