import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest, merge, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { JiraService } from 'src/app/services/jira.service';
import { MeetingsService } from 'src/app/services/meetings.service';
import { bookingDialogComponent } from '../bookingDialog/bookingDialog.component';
import { FiltersService } from './../../services/filters.service';

@Component({
    selector: 'summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, AfterViewInit {
    constructor(
        public jiraService: JiraService,
        public meetingService: MeetingsService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public filtersService: FiltersService
    ) {}
    @ViewChild(MatSort) sort: MatSort;

    workingHoursFormControl = new FormControl();
    defaultComponentFormControl = new FormControl('AN_API_ID_5111002601');

    loading = true;
    ticketWithoutComponent = false;

    dataSource = new MatTableDataSource<TimeSummary>([]);
    ngOnInit() {
        this.filtersService.range$.subscribe(() => {
            combineLatest([
                merge(of([]), this.jiraService.ticketsWithTime().pipe(finalize(() => (this.loading = false)))),
                this.meetingService.meetingComponents$,
                merge(of(this.workingHoursFormControl.value), this.workingHoursFormControl.valueChanges),
                merge(of(this.defaultComponentFormControl.value), this.defaultComponentFormControl.valueChanges),
            ])
                .pipe(
                    map(([tickets, meetings, workingHours, defaultComponent]) => {
                        const ticketComponents = tickets.map(
                            (ticket) => <[string, number]>[ticket.component, ticket.timeSpent || 0]
                        );
                        const meetingsComponents = meetings
                            .filter(([, { component }]) => component !== '')
                            .map(([, { component, timeSpent }]) => <[string, number]>[component, timeSpent || 0]);
                        const defaultComponentTime =
                            workingHours * 60 -
                            [...ticketComponents, ...meetingsComponents].reduce((sum, [, time]) => sum + time, 0);

                        const componentsWorklogs = [
                            ...[...ticketComponents, ...meetingsComponents, [defaultComponent, defaultComponentTime]]
                                .reduce((componentsMap, [component, timeSpent]) => {
                                    componentsMap.set(component, (componentsMap.get(component) || 0) + timeSpent);
                                    return componentsMap;
                                }, new Map<string, number>())
                                .entries(),
                        ];

                        return componentsWorklogs
                            .filter(([componentFullName]) => {
                                if (componentFullName === '') {
                                    this.ticketWithoutComponent = true;
                                }
                                return componentFullName !== '';
                            })
                            .map(([componentFullName, timeSpent]) => {
                                const matches = <{ codeType: 'PS' | 'ID' | string; code: string; component: string }>(
                                    /AN_(?<component>.*)_?(?<codeType>ID|PS)?_\s?(?<code>\d+)/.exec(componentFullName)
                                        ?.groups
                                );
                                return {
                                    ...matches,
                                    timeSpent,
                                    defaultComponent: componentFullName === this.defaultComponentFormControl.value,
                                };
                            });
                    })
                )
                .subscribe((data) => {
                    this.dataSource.data = data;
                });
        });
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    openBookingModal() {
        if (this.workingHoursFormControl.value && this.defaultComponentFormControl.value)
            this.dialog.open(bookingDialogComponent, {
                data: {
                    data: this.dataSource.data,
                    workingHours: this.workingHoursFormControl.value,
                    defaultComponenetHours: <number>this.defaultComponentFormControl.value,
                },
            });
        else {
            this.snackBar.open('Working hours input or default component has no value.', undefined, {
                duration: 2000,
            });
        }
    }
}

export interface TimeSummary {
    codeType: 'PS' | 'ID' | string;
    code: string;
    component: string;
    timeSpent: number;
    defaultComponent: boolean;
}
