<div class="footer" fxLayout="row" fxLayoutAlign="start center">
    <span class="footer-element regular-text p-16">Viessmann</span>

    <div fxFlex="1 0 auto"></div>

    <span class="footer-element semi-bold-text p-16">contact</span>
    <span class="footer-element semi-bold-text p-16">privacy policy</span>

    <div class="footer-element"></div>
</div>
