<h1 mat-dialog-title>Booking summary</h1>
<div>
    <mat-card>
        <label>Pick your free text</label><br />
        <mat-radio-group
            aria-label="Pick your free text"
            [(ngModel)]="freeTextOption"
            (ngModelChange)="OnChangesFreeTextOption($event)"
        >
            <mat-radio-button *ngFor="let option of freeTextOptionsKeys" [value]="option">
                {{ freeTextOptions[option] }}
            </mat-radio-button>
        </mat-radio-group>
    </mat-card>

    <mat-card>
        <label>Booking location</label><br />
        <mat-radio-group
            aria-label="Pick booking location"
            [(ngModel)]="activityType"
            (ngModelChange)="OnChangeBookingLocation($event)"
        >
            <mat-radio-button *ngFor="let option of activityTypes" [value]="option.value">
                {{ option.name }}
            </mat-radio-button>
        </mat-radio-group>
    </mat-card>
</div>
<div mat-dialog-content>
    <table mat-table [dataSource]="dataSource" class="booking-summary-checkout">
        <ng-container matColumnDef="include">
            <th mat-header-cell *matHeaderCellDef class="p-16 regular-text">include</th>
            <td mat-cell *matCellDef="let component">
                <div class="col-include">
                    <mat-checkbox [color]="'primary'" [(ngModel)]="component.include"></mat-checkbox>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="costCenter">
            <th mat-header-cell *matHeaderCellDef class="p-16 regular-text">cost center</th>
            <td mat-cell *matCellDef="let component">
                <div class="col-const-center">
                    <mat-form-field>
                        <input
                            [ngModel]="component.costCenter"
                            (ngModelChange)="component.costCenter = $event"
                            (focusout)="OnChangesCostCenter(component)"
                            matInput
                        />
                    </mat-form-field>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="hours">
            <th mat-header-cell *matHeaderCellDef class="p-16 regular-text">hours</th>
            <td mat-cell *matCellDef="let component">
                <div class="col-hours">
                    <span *ngIf="!component.newRow && !component.defaultComponent">{{ component.hours }}h</span>
                    <mat-form-field *ngIf="component.newRow || component.defaultComponent" class="newRowInput">
                        <mat-hint>hours</mat-hint>
                        <input [(ngModel)]="component.hours" matInput />
                    </mat-form-field>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="projectId">
            <th mat-header-cell *matHeaderCellDef class="p-16 regular-text">project Id</th>
            <td mat-cell *matCellDef="let component">
                <div class="col-project-id">
                    <span *ngIf="!component.newRow">{{ component.projectId }}</span>
                    <mat-form-field *ngIf="component.newRow" class="newRowInput">
                        <input [(ngModel)]="component.projectId" matInput />
                    </mat-form-field>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="activityType">
            <th mat-header-cell *matHeaderCellDef class="p-16 regular-text">activity type</th>
            <td mat-cell *matCellDef="let component">
                <div class="col-activity-type">
                    <mat-form-field>
                        <input [(ngModel)]="component.activityType" matInput />
                    </mat-form-field>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="accountName">
            <th mat-header-cell *matHeaderCellDef class="p-16 regular-text">Account Name</th>
            <td mat-cell *matCellDef="let component">
                <div class="col-accountname">
                    <span *ngIf="!component.newRow">{{ component.accountName }}</span>
                    <mat-form-field *ngIf="component.newRow" class="newRowInput">
                        <input [(ngModel)]="component.accountName" matInput disabled />
                    </mat-form-field>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="freeText">
            <th mat-header-cell *matHeaderCellDef class="p-16 regular-text">free text</th>
            <td mat-cell *matCellDef="let component">
                <div class="col-free-text">
                    <mat-form-field>
                        <input [(ngModel)]="component.freeText" matInput />
                    </mat-form-field>
                </div>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="[
                'include',
                'costCenter',
                'hours',
                'activityType',
                'projectId',
                'accountName',
                'freeText'
            ]"
        ></tr>
        <tr
            mat-row
            *matRowDef="
                let row;
                columns: ['include', 'costCenter', 'hours', 'activityType', 'projectId', 'accountName', 'freeText']
            "
        ></tr>
    </table>
    <article class="table-header-bottom">
        <button class="button-add-row" mat-button (click)="addRow()">Add Row</button>
        <mat-error *ngIf="hasError()">Summary hours are not equal working hours</mat-error>
    </article>
    <div *ngIf="result?.activityReportSave?.success">
        <strong>Booking created with id {{ result.bookingId }}.</strong> You can see all your bookings on
        <a [attr.href]="result.activityReportSave.activityReportUrl">{{
            result.activityReportSave.activityReportUrl
        }}</a>
    </div>
    <pre *ngIf="result">{{ result | json }}</pre>
</div>
<div mat-dialog-actions>
    <button
        color="primary"
        class="book-button"
        mat-raised-button
        [disabled]="loading || clicked"
        [ngClass]="{ booked: clicked }"
        (click)="book()"
    >
        Proceed with bookings
    </button>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <span *ngIf="clicked">Booking ended with success</span>
</div>
