/* eslint-disable import/namespace */
/* eslint-disable no-console */
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { FiltersService } from 'src/app/services/filters.service';
import { JiraService } from './../../services/jira.service';

@Component({
    selector: 'filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
    @ViewChild(MatSort) sort: MatSort;

    loading = true;

    favoriteProject = this.filtersService.getFavoriteProject();

    jiraProject = new FormControl(this.filtersService.getFavoriteProject());

    jiraProjects: { name: string; key: string }[];

    range = new FormGroup({
        // eslint-disable-next-line import/namespace
        start: new FormControl(moment.utc(moment().startOf('month')).toDate()),
        end: new FormControl(moment.utc(moment()).toDate()),
    });

    constructor(public filtersService: FiltersService, private jiraService: JiraService) {}

    onChangeEventEnd(value: Date) {
        if (value !== null) {
            this.filtersService.setRange({
                start: moment.utc(moment(this.range.value.start)).toDate(),
                end: moment.utc(moment(value)).toDate(),
            });
        }
    }

    OnChangesJiraProject(project: string) {
        this.filtersService.setFavoriteProject(project);
    }

    OnClickMarkFavorite() {
        localStorage.setItem('favoriteProject', this.jiraProject.value);
        this.favoriteProject = this.jiraProject.value;
    }

    OnClickUnMarkFavorite() {
        localStorage.setItem('favoriteProject', '');
        this.favoriteProject = '';
    }

    async ngOnInit() {
        this.jiraProjects = await this.jiraService.getProjects();
    }
}
