<mat-card>
    <h2>Tickets you logged time</h2>
    <h3>in current date ({{ startOfMonth | date: 'YYYY-MM-dd' }} - {{ now | date: 'YYYY-MM-dd' }})</h3>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

    <table mat-table matSort [dataSource]="dataSource" class="tickets">
        <ng-container matColumnDef="ticket">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-16 regular-text">ticket</th>
            <td mat-cell *matCellDef="let ticket">
                <div class="ticket-id">
                    <a *ngIf="!ticket.parent" [href]="ticket.url" target="_blank">
                        <strong>{{ ticket.ticket }}</strong>
                        <span>{{ ticket.description }}</span>
                    </a>
                    <a *ngIf="ticket.parent" [href]="ticket.parent.url" target="_blank">
                        <strong>{{ ticket.parent.ticket }} / {{ ticket.ticket }}</strong>
                        <span>{{ ticket.parent.description }} / {{ ticket.description }}</span>
                    </a>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="component">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-16 regular-text">component</th>
            <td mat-cell *matCellDef="let element">
                <span class="ticket-component" *ngIf="element.component">{{ element.component }}</span>
                <span class="ticket-warning-component" *ngIf="!element.component"
                    >No component, please fill in ticket</span
                >
            </td>
        </ng-container>

        <ng-container matColumnDef="timeSpent">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-16 regular-text">Time spend</th>
            <td mat-cell *matCellDef="let element">{{ element.timeSpent | worklog }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['ticket', 'component', 'timeSpent']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['ticket', 'component', 'timeSpent']"></tr>
    </table>
</mat-card>
