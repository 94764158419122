<div class="summary">
    <mat-card>
        <h2>Summary</h2>
        <mat-form-field>
            <mat-label>Total working hours</mat-label>
            <input [formControl]="workingHoursFormControl" matInput />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Default component</mat-label>
            <input [formControl]="defaultComponentFormControl" matInput />
        </mat-form-field>
        <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

        <table mat-table matSort [dataSource]="dataSource" class="components">
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-16 regular-text">code</th>
                <td mat-cell *matCellDef="let component">
                    <div class="component">
                        <span>{{ component.component }}</span
                        ><br />
                        <strong>code:</strong> {{ component.code }}<br />
                        <ng-container *ngIf="component.codeType === 'PS'">
                            <strong>free text:</strong> {{ component.component }}
                        </ng-container>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="timeSpent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-16 regular-text">time</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.timeSpent | worklog }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['code', 'timeSpent']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['code', 'timeSpent']"></tr>
        </table>
        <button
            color="primary"
            class="book-button"
            mat-raised-button
            (click)="openBookingModal()"
            [disabled]="ticketWithoutComponent"
        >
            Book
        </button>
    </mat-card>
</div>
