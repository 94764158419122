import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'worklog',
})
export class WorklogPipe implements PipeTransform {
    transform(value: number): string {
        if (isNaN(value) || value == 0) {
            return '---';
        }
        const hours = value / 60;
        return `${hours.toFixed(1)}h`;
    }
}
