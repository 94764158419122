/* eslint-disable import/namespace */
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

interface Range {
    start: Date;
    end: Date;
}

@Injectable({
    providedIn: 'root',
})
export class FiltersService {
    favoriteProject$ = new BehaviorSubject(localStorage.getItem('favoriteProject') || '');
    range$ = new BehaviorSubject<Range>({
        start: moment.utc(moment().startOf('month')).toDate(),
        end: moment.utc(moment()).toDate(),
    });

    setRange(range: Range) {
        this.range$.next(range);
    }

    getEndDate() {
        return this.range$.getValue().end;
    }

    getEndDateDay() {
        const date = this.range$.getValue().end;
        const hour = 23 - date.getTimezoneOffset() / 60;
        return new Date(date.setHours(hour, 59, 59, 999));
    }

    getStartDate() {
        return this.range$.getValue().start;
    }

    getFavoriteProject() {
        return this.favoriteProject$.getValue();
    }

    setFavoriteProject(project: string) {
        this.favoriteProject$.next(project);
    }
}
