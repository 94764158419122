/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { TimeSummary } from '../summary/summary.component';
import { FiltersService } from './../../services/filters.service';

@Component({
    selector: 'booking-dialog',
    templateUrl: './bookingDialog.component.html',
    styleUrls: ['./bookingDialog.component.scss'],
})
export class bookingDialogComponent {
    freeTextOption: string;
    activityType: string;
    dataSource = new MatTableDataSource<Bookings>([]);
    result: BookingResponse;
    loading = false;
    clicked = false;

    freeTextOptions: { [key: string]: string } = {
        development: 'API Development',
        qa: 'API testing',
        devops: 'API DevOps',
    };
    freeTextOptionsKeys = Object.keys(this.freeTextOptions);

    WROCLAW_ACTIVITY_CODE = '116';
    activityTypes = [
        { name: 'Wroclaw', value: this.WROCLAW_ACTIVITY_CODE },
        { name: 'Allendorf ', value: '823' },
    ];

    activityTypesSelected: string;

    actualFreeTextOption: string = ' ';
    workingHours: number;
    constructor(
        @Inject(MAT_DIALOG_DATA) data: { data: TimeSummary[]; workingHours: number; defaultComponenetHours: number },
        private http: HttpClient,
        private filtersService: FiltersService,
        public snackBar: MatSnackBar
    ) {
        this.workingHours = data.workingHours;
        this.dataSource.data = data.data.map((record) => ({
            include: true,
            costCenter: this.getCostCenter(record.code, this.WROCLAW_ACTIVITY_CODE),
            hours: (record.timeSpent / 60).toString(),
            projectId: record.code,
            freeText: record.codeType === 'PS' ? record.component : this.actualFreeTextOption,
            activityType: this.WROCLAW_ACTIVITY_CODE,
            accountName: record.component,
            defaultComponent: record.defaultComponent,
        }));
    }

    OnChangesFreeTextOption(key: string) {
        this.actualFreeTextOption = this.freeTextOptions[key];
        this.dataSource.data = this.dataSource.data.map((r) => {
            return {
                ...r,
                freeText: this.freeTextOptions[key],
            };
        });
    }

    OnChangeBookingLocation(key: string) {
        this.activityTypesSelected = key;
        this.dataSource.data = this.dataSource.data.map((r) => {
            return {
                ...r,
                activityType: key,
                costCenter: this.getCostCenter(r.projectId, this.activityTypesSelected),
            };
        });
    }

    private getCostCenter(projectId: string, activityTypeCode: string) {
        return activityTypeCode === this.WROCLAW_ACTIVITY_CODE ? '0001110907' : '0004558056';
    }

    OnChangesCostCenter(row: Bookings) {
        row.costCenter = row.costCenter.padStart(10, '0');
    }

    addRow() {
        const newRow = {
            id: new Date().getTime(),
            include: true,
            costCenter: '',
            hours: '0',
            projectId: '',
            freeText: this.actualFreeTextOption,
            activityType: this.activityTypesSelected,
            accountName: '',
            newRow: true,
            defaultComponent: false,
        };
        newRow.costCenter = this.getCostCenter(newRow.projectId, this.activityTypesSelected);
        this.dataSource.data = [...this.dataSource.data, newRow];
    }

    getRowHours() {
        return this.dataSource.data
            .map((record) => {
                return Number(record.hours);
            })
            .reduce((prev, curr) => {
                return prev + curr;
            }, 0);
    }

    hasError() {
        const rowsHours = this.getRowHours();

        if (rowsHours != <number>this.workingHours) {
            return true;
        }
        return false;
    }

    book() {
        this.loading = true;
        const data = this.dataSource.data
            .filter((record) => record.include)
            .map(({ accountName, include, defaultComponent, newRow, id, ...record }) => record);
        this.http
            .post<BookingResponse>(`${environment.apiUrl}/account-booking/v1/bookings`, data, {
                params: {
                    startDate: this.filtersService.getStartDate().toString(),
                    endDate: this.filtersService.getEndDate().toString(),
                },
            })
            .subscribe(
                (result) => {
                    this.result = result;
                    this.loading = false;
                    this.clicked = true;
                },
                (e) => {
                    this.loading = false;
                    this.result = e.error;
                }
            );
    }
}

interface Bookings {
    include: boolean;
    costCenter: string;
    hours: string;
    freeText: string;
    projectId: string;
    accountName: string;
    newRow?: boolean;
    defaultComponent?: boolean;
    id?: number;
    activityType: string;
}

interface BookingResponse {
    bookingId: number;
    bookingSummary: object;
    activityReportSave: {
        success: boolean;
        activityReportUrl?: string;
    };
}
